////
/// List
/// @group base
////

ol,
ul {
  padding: 0;
  list-style: none;

  li {
    position: relative;

    &:not(:last-child) {
      // @include v(margin-bottom, small);
    }

    &::before {
      // @include absolute(top-left);
      // @include v(font-weight, bold);
    }
  }
}

ol {
  counter-reset: ordered-list;

  > li {
    counter-increment: ordered-list;

    &::before {
      // @include tabular-lining-numerals;
      content: counter(ordered-list, decimal) '.';
    }
  }

  &[reversed] {
    display: flex;
    flex-direction: column-reverse;
  }
}

dt {
  width: auto;
  clear: left;
  font-weight: 600;
  min-width: 10rem;

  @include above('small') {
    float: left;
  }
}

dd {
  display: block;
  margin: 0 0 size('padding-s');
  max-width: $max-paragraph-width;
  overflow: hidden;

  @include above('small') {
    display: table-cell;
    padding: 0 0 size('padding-s') size('padding-s');
    vertical-align: top;
  }
}
