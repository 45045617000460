////
/// Heading
/// @group base
////

// Heading Elements
h1,
h2,
h3,
h4,
h5,
h6 {
  color: color('red');
  font-family: font-family(text);
  font-weight: 600;

  // TODO rework to be a bit more accessible
  a {
    color: inherit;
    text-decoration: none;
  }
}

h1 {
  font-size: size('text-xl');
  line-height: size('line-height-narrower');
}

h2 {
  font-size: size('text-l');
  line-height: size('line-height-narrow');
}

h3 {
  font-size: size('text-l');
  line-height: size('line-height-narrow');
}

h4 {
  font-size: size('text-l');
  line-height: size('line-height-narrow');
}

h5 {
}

h6 {
}
