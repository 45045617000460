.speaker-list {
  max-width: $max-paragraph-width;

  @include above('medium') {
    padding-right: 0;
    padding-left: 0;
  }

  &__card {
    display: grid;
    margin-bottom: size('padding');

    div {
      grid-area: image;
    }

    h3,
    h4 {
      font-size: size('text-m');
    }

    h3 {
      margin: 0;
      color: color('purple');
      grid-area: name;
      writing-mode: vertical-lr;

      > a:visited { // stylelint-disable-line max-nesting-depth
        color: color('purple');
      }
    }

    h4 {
      margin: 0;
      grid-area: title;
    }
  }

  &__card:nth-child(odd) {
    grid-template-areas: 'image name'
      'title title';
    grid-template-columns: 60% 1fr;
  }

  &__card:nth-child(even) {
    grid-template-areas: 'name image'
      'title title';
    grid-template-columns: 1fr 60%;

    h3 {
      transform: rotate(180deg);
    }

    h4 {
      text-align: right;
    }
  }

  &__card--small {
    h4 {
      display: none;
    }
  }
}
