.button-icon {
  $icon-space: 1.5em;
  padding-left: $icon-space;

  &[rel~=external] {
    padding-left: $icon-space;
  }

  &--pin {
    background-image: url('/images/icon-map.svg');
    background-position: 0.5em center;
    background-repeat: no-repeat;
    background-size: 0.7em;
  }
}
