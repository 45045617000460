@import 'node_modules/accoutrement-color/sass/color';

// Colors
// @see http://oddbird.net/accoutrement-color/docs/
// @see http://oddbird.net/herman/docs/config-colors.html
$brand-colors: (
  'yellow-light': #fae100,
  'yellow-screen': #ecb256,
  'yellow': #dda700,
  'orange-light': #f36e2e,
  'orange-screen': #e05666,
  'orange': #c83f18,
  'red-light': #f22670,
  'red-screen': #df3180,
  'red': #c61440,
  'purple-screen': #b03a8f,
  'purple': #711f56,
);

$neutral-colors: (
  'white-ish': #f7f5f5,
  'light-grey': #e5dede,
  'dark-grey': #8b8080
);

$theme-colors: (
  'background': 'white-ish',
  'text': 'purple',
  'headings': 'red',
  'highlight': 'orange',
  'link-active': 'red',
  'link-hover': #f22670,
  'link-visited': 'red-screen',
  'selection': 'purple',
  'selection-bg': 'yellow-light'
);

$system-colors: (
  'contrast-light': 'text',
  'contrast-dark': 'background',
);

@include add-colors($brand-colors, $neutral-colors, $theme-colors, $system-colors);
