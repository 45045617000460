@charset "UTF-8";
@font-face {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/Dosis-ExtraBold.ttf") format("truetype"); }

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-size-adjust: 100%; }

main {
  display: block; }

details {
  display: block; }

pre {
  overflow: auto; }

progress {
  display: inline-block; }

summary {
  display: block; }

template {
  display: none; }

*,
::before,
::after {
  border-style: solid;
  border-width: 0;
  box-sizing: border-box; }

* {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

audio :not([controls]) {
  display: none; }

[aria-busy='true'] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

[hidden] {
  display: none; }

button {
  overflow: visible; }

input {
  border-radius: 0;
  line-height: normal; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
  cursor: pointer; }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border: 0 none !important;
    padding: 0 !important; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number'] {
  width: auto; }

[type='search'] {
  appearance: textfield; }

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit; }

textarea {
  overflow: auto;
  resize: vertical; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

audio,
canvas,
iframe,
img,
svg,
video {
  max-width: 100%;
  vertical-align: middle; }

a {
  background-color: transparent; }
  a:active, a:hover {
    outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: inherit; }

dfn {
  font-style: italic; }

nav ol,
nav ul {
  list-style: none; }

small {
  font-size: 0.75em; }

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

ul,
dl,
ol,
p {
  margin-top: 1.25rem; }

h1,
h2,
h3 {
  margin-top: 2.5rem; }
  h1:first-child,
  h2:first-child,
  h3:first-child {
    margin-top: 0; }

.speaker-list,
iframe {
  margin-top: 2.5rem; }

*, *::before, *::after {
  box-sizing: border-box; }

html {
  font-family: "Dosis", "Helvetica Neue", "sans-serif";
  position: relative;
  font-size: 100%;
  /* this means 16px */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: oldstyle-nums;
  font-weight: 300;
  line-height: 1.4;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }
  @media (min-width: 36rem) {
    html {
      font-size: 112.5%;
      /* this means 18px */ } }
  @media (min-width: 45rem) {
    html {
      font-size: 125%;
      /* this means 20px */ } }

body {
  margin: 0;
  background-color: #f7f5f5;
  color: #711f56; }
  @media (min-width: 36rem) {
    body {
      background-image: url("/images/bg-tile.svg");
      background-repeat: repeat;
      background-size: 50px; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #c61440;
  font-family: "Dosis", "Helvetica Neue", "sans-serif";
  font-weight: 600; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
    text-decoration: none; }

h1 {
  font-size: 3.05176rem;
  line-height: 1; }

h2 {
  font-size: 1.5625rem;
  line-height: 1.2; }

h3 {
  font-size: 1.5625rem;
  line-height: 1.2; }

h4 {
  font-size: 1.5625rem;
  line-height: 1.2; }

a {
  position: relative;
  color: #c61440;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: currentColor;
  text-decoration-skip: ink;
  text-decoration-skip-ink: auto; }
  a:hover, a:focus {
    color: #f22670; }
  a:visited {
    color: #df3180; }
  a.is-button, a[rel~='external'] {
    display: inline-block;
    padding: 0.2rem 0.4rem;
    background-color: #c61440;
    border-radius: 3px;
    color: #f7f5f5;
    text-decoration: none; }
    a.is-button:hover, a.is-button:focus, a[rel~='external']:hover, a[rel~='external']:focus {
      background-color: #f22670; }
    a.is-button:visited, a[rel~='external']:visited {
      background-color: #df3180; }
  a.is-disabled {
    cursor: not-allowed;
    text-decoration: line-through; }
    a.is-disabled:hover {
      color: #c61440; }

p {
  max-width: 37em; }
  p > img {
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    float: left; }

ol,
ul {
  padding: 0;
  list-style: none; }
  ol li,
  ul li {
    position: relative; }

ol {
  counter-reset: ordered-list; }
  ol > li {
    counter-increment: ordered-list; }
    ol > li::before {
      content: counter(ordered-list, decimal) "."; }
  ol[reversed] {
    display: flex;
    flex-direction: column-reverse; }

dt {
  width: auto;
  clear: left;
  font-weight: 600;
  min-width: 10rem; }
  @media (min-width: 36rem) {
    dt {
      float: left; } }

dd {
  display: block;
  margin: 0 0 1.25rem;
  max-width: 37em;
  overflow: hidden; }
  @media (min-width: 36rem) {
    dd {
      display: table-cell;
      padding: 0 0 1.25rem 1.25rem;
      vertical-align: top; } }

hr {
  display: block;
  width: 100%;
  border: 0;
  overflow: visible; }

abbr[title],
dfn[title] {
  border-bottom-color: #711f56;
  border-bottom-style: alternate;
  border-bottom-width: hairline;
  color: #711f56;
  cursor: help;
  font-style: italic; }

abbr[title],
dfn[title],
ins {
  text-decoration: none; }

strong {
  font-weight: 600; }

sub,
sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.4em;
  font-variant-position: sub; }

sup {
  top: -0.4em;
  font-variant-position: super; }

a,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

::selection {
  background-color: #fae100;
  color: #711f56; }

section {
  margin-bottom: 3.05176rem; }
  @media (min-width: 45rem) {
    section {
      padding: 0 1.5625rem; } }
  section:first-child > h2:first-child {
    margin-top: 1.5625rem; }
  section > h1,
  section > h2 {
    padding: 0 1.5625rem;
    padding-bottom: 20px;
    background-color: #711f56;
    background-image: linear-gradient(to top, #ecb256, #ecb256 5px, #e05666 5px, #e05666 10px, #df3180 10px, #df3180 15px, #b03a8f 15px, #b03a8f 20px, #711f56 20px, #711f56);
    color: #f7f5f5; }
    @media (min-width: 36rem) {
      section > h1,
      section > h2 {
        padding-bottom: 0;
        background-color: transparent;
        background-image: none;
        color: #c61440; } }
    section > h1.no-bg,
    section > h2.no-bg {
      padding-bottom: 0;
      background-color: transparent;
      background-image: none;
      color: #c61440; }
  section > ul,
  section > dl,
  section > p {
    padding: 0 1.5625rem; }
    @media (min-width: 45rem) {
      section > ul,
      section > dl,
      section > p {
        padding: 0; } }

article {
  margin: 0 auto 3.05176rem;
  padding: 0 1.5625rem;
  max-width: 55.5em; }
  article > section,
  article > section > * {
    padding: 0; }
  article > section {
    margin-top: 3.05176rem; }
  article > h1:first-child {
    margin-top: 1.5625rem; }
    @media (min-width: 36rem) {
      article > h1:first-child {
        margin-top: 0; } }

blockquote {
  color: #c83f18;
  font-weight: 600;
  max-width: 37em; }
  blockquote cite,
  blockquote em {
    font-style: normal; }
  blockquote p::before {
    content: open-quote; }
  blockquote p::after {
    content: close-quote; }
  blockquote cite {
    display: block;
    font-style: normal;
    text-align: right; }
    blockquote cite::before {
      content: '– '; }

details {
  margin-top: 1rem; }
  details pre {
    font-family: inherit;
    white-space: pre-wrap; }

body > header {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 100%;
  max-width: 55.5em; }
  @media (min-width: 45rem) {
    body > header {
      background-color: transparent; } }
  body > header > .site-nav {
    grid-area: 1; }

body > footer {
  padding: 3.05176rem 1.5625rem;
  background-image: linear-gradient(179deg, #ecb256, #ecb256 20px, #e05666 20px, #e05666 30px, #df3180 30px, #df3180 40px, #b03a8f 40px, #b03a8f 50px, #711f56 50px, #711f56);
  color: #f7f5f5; }
  body > footer a {
    color: #f7f5f5; }

.site-nav {
  margin: 0.5em 0;
  text-align: center; }

.site-title {
  margin: 0;
  padding: 1rem;
  background-color: #711f56;
  color: #711f56;
  font-size: 1.5625rem;
  line-height: 1.2; }
  @media (min-width: 36rem) {
    .site-title {
      display: grid;
      padding: 0;
      background-color: transparent;
      grid-template-areas: 'title image';
      grid-template-columns: 1fr 60%; } }
  .site-title::before {
    content: '';
    display: block;
    height: 30vw;
    background-image: url("/images/SOTB-monitors.svg");
    background-repeat: no-repeat;
    background-size: contain; }
    @media (min-width: 36rem) {
      .site-title::before {
        height: 15vw;
        margin: 1rem 1rem 0 0;
        grid-area: image; } }
  .site-title a {
    display: block;
    height: 3em;
    font-size: calc(3rem + 1.25 * (100vw - 20rem) / (60 - 20));
    font-weight: 800;
    grid-area: title;
    text-align: center; }
    @media (min-width: 36rem) {
      .site-title a {
        height: 4em;
        margin: 0.5em 0 0 1rem;
        background-image: url("/images/halftone-pattern-circle.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain; } }
  .site-title span {
    text-transform: uppercase; }
  .site-title span:first-child,
  .site-title span:nth-child(4) {
    display: block; }
  .site-title span:nth-child(2),
  .site-title span:nth-child(3) {
    height: 1em;
    position: relative;
    top: -1.2em;
    font-size: 0.5em; }
  .site-title span:first-child {
    margin-top: 0.5em;
    color: #ecb256; }
    @media (min-width: 36rem) {
      .site-title span:first-child {
        color: #dda700; } }
  .site-title span:nth-child(2) {
    color: #e05666; }
    @media (min-width: 36rem) {
      .site-title span:nth-child(2) {
        color: #c83f18; } }
  .site-title span:nth-child(3) {
    color: #df3180; }
    @media (min-width: 36rem) {
      .site-title span:nth-child(3) {
        color: #c61440; } }
  .site-title span:nth-child(4) {
    position: relative;
    top: -0.85em;
    color: #b03a8f; }
    @media (min-width: 36rem) {
      .site-title span:nth-child(4) {
        color: #711f56; } }

.nav-menu {
  display: none; }
  @media (min-width: 36rem) {
    .nav-menu {
      display: block; } }
  .nav-menu:target, .nav-menu[aria-expanded='true'] {
    display: block; }
  .nav-menu:target .nav-menu__entries,
  .nav-menu[aria-expanded='true'] .nav-menu__entries {
    display: block; }
  .nav-menu:target ~ .nav-menu__toggle {
    display: none; }
  @media (min-width: 36rem) {
    .nav-menu li {
      display: inline-block;
      padding: 0.4em 0.8em; } }
  .nav-menu__entries {
    margin: 0.5em 0 0;
    padding-left: 0;
    list-style: none;
    text-align: center; }
    @media (min-width: 36rem) {
      .nav-menu__entries {
        margin-top: 0; } }
    .nav-menu__entries > * + * {
      margin-top: 0.3em; }
  .nav-menu__toggle, .nav-menu__close {
    padding-left: 27px;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: 20px;
    text-decoration: none;
    text-transform: uppercase; }
    .nav-menu__toggle:visited, .nav-menu__close:visited {
      color: #c61440; }
  .nav-menu__toggle {
    background-image: url("/images/icon-menu.svg"); }
    @media (min-width: 36rem) {
      .nav-menu__toggle {
        display: none; } }
  .nav-menu__close {
    height: 1em;
    background-image: url("/images/icon-menu-close.svg"); }
    @media (min-width: 36rem) {
      .nav-menu__close {
        display: none; } }

[hidden],
.is-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px 1px 1px 1px);
  height: 1px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 1px; }

.is-focusable:not([href]), .is-focusable[href]:not(:focus):not(:active) {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px 1px 1px 1px);
  height: 1px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 1px; }

.footer-links {
  padding: 0;
  list-style-type: none; }
  .footer-links > li + li {
    margin-top: 1.25rem; }

.button-icon {
  padding-left: 1.5em; }
  .button-icon[rel~=external] {
    padding-left: 1.5em; }
  .button-icon--pin {
    background-image: url("/images/icon-map.svg");
    background-position: 0.5em center;
    background-repeat: no-repeat;
    background-size: 0.7em; }

.image-shape--round {
  shape-outside: circle(50%); }

.footer-content {
  margin: 0 auto;
  max-width: 55.5em; }
  @media (min-width: 36rem) {
    .footer-content {
      display: grid;
      grid-gap: 1.25rem;
      grid-template-areas: 'info links';
      grid-template-columns: 60% 1fr; } }
  .footer-content__information {
    grid-area: info; }
  .footer-content img {
    float: none;
    max-width: 150px; }

.speaker-list {
  max-width: 37em; }
  @media (min-width: 45rem) {
    .speaker-list {
      padding-right: 0;
      padding-left: 0; } }
  .speaker-list__card {
    display: grid;
    margin-bottom: 1.5625rem; }
    .speaker-list__card div {
      grid-area: image; }
    .speaker-list__card h3,
    .speaker-list__card h4 {
      font-size: 1.25rem; }
    .speaker-list__card h3 {
      margin: 0;
      color: #711f56;
      grid-area: name;
      writing-mode: vertical-lr; }
      .speaker-list__card h3 > a:visited {
        color: #711f56; }
    .speaker-list__card h4 {
      margin: 0;
      grid-area: title; }
  .speaker-list__card:nth-child(odd) {
    grid-template-areas: 'image name' 'title title';
    grid-template-columns: 60% 1fr; }
  .speaker-list__card:nth-child(even) {
    grid-template-areas: 'name image' 'title title';
    grid-template-columns: 1fr 60%; }
    .speaker-list__card:nth-child(even) h3 {
      transform: rotate(180deg); }
    .speaker-list__card:nth-child(even) h4 {
      text-align: right; }
  .speaker-list__card--small h4 {
    display: none; }

.image-wrap {
  position: relative;
  border-color: #711f56;
  border-style: solid;
  border-width: 7px 30px 7px 10px;
  line-height: 0; }
  .image-wrap img {
    max-width: 100%; }
  .image-wrap a {
    display: block;
    width: 100%;
    height: 100%;
    background-blend-mode: screen;
    background-color: #c61440;
    background-size: cover; }
    .image-wrap a:hover {
      background-color: #c83f18; }
  .image-wrap a > img {
    visibility: hidden; }
  .image-wrap--portrait::after, .image-wrap--no-anchor::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; }
  .image-wrap--portrait {
    width: 200px;
    border-width: 10px 7px 30px; }
    .image-wrap--portrait::after {
      background-color: #c61440;
      mix-blend-mode: screen; }
  .image-wrap--no-anchor::after {
    background-color: #8b8080;
    mix-blend-mode: lighten; }

.list-inline {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  grid-area: links; }
  .list-inline > li {
    margin-top: 1.25rem;
    margin-right: 1.25rem;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0; }

.list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12em, 1fr)); }
  .list-grid > li {
    margin-top: 1.25rem;
    margin-right: 1.25rem;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0; }
    .list-grid > li,
    .list-grid > li > a {
      display: flex;
      flex-direction: row;
      align-items: center; }
    .list-grid > li > a {
      width: 100%; }
  .list-grid img {
    width: 4rem;
    margin-right: 0.5rem; }

.sponsor-list {
  display: flex;
  justify-content: center; }
  .sponsor-list .logo {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .sponsor-list > * + * {
    margin-left: 1.25rem; }
  .sponsor-list--headline .logo {
    flex-basis: 400px; }
  .sponsor-list--major .logo {
    flex-basis: 400px; }
  .sponsor-list--major img {
    max-width: 50%; }
  .sponsor-list--major p:first-of-type {
    text-align: center; }
  .sponsor-list--medium .logo {
    flex-basis: 250px; }
  .sponsor-list--small .logo {
    flex-basis: 200px;
    justify-self: center; }
  .sponsor-list img {
    width: 100%; }

.schedule {
  font-size: 1.5625rem; }
  .schedule__slot {
    box-sizing: border-box;
    position: relative;
    margin-left: 20px;
    padding-bottom: 3.05176rem;
    padding-left: 40px;
    border-color: #dda700;
    border-style: solid;
    border-left-width: 3px; }
    @media (min-width: 45rem) {
      .schedule__slot {
        max-width: 50%;
        margin-left: 50%; } }
    .schedule__slot::before {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      left: -calc(40px/2 + 1.5px);
      background-image: url("/images/roundel-yellow.svg");
      background-repeat: no-repeat; }
    @media (min-width: 45rem) {
      .schedule__slot:nth-child(even) {
        max-width: calc(50% + 3px);
        text-align: right;
        margin-left: 0;
        margin-right: calc(50% - 3px);
        padding-right: 40px;
        padding-left: 0;
        border-right-width: 3px;
        border-left-width: 0; }
        .schedule__slot:nth-child(even)::before {
          left: auto;
          right: -calc(40px/2 + 3px); } }
    .schedule__slot:last-child {
      padding-bottom: 0;
      border-left-color: transparent; }
    .schedule__slot--talk::before {
      background-image: url("/images/roundel-red.svg"); }
    .schedule__slot time {
      font-weight: 600; }
    .schedule__slot h2 {
      margin-top: 0;
      color: #c83f18; }
    .schedule__slot a {
      color: #c61440;
      text-decoration: underline; }
      .schedule__slot a[rel*="external"] {
        color: #ffffff; }

.twitter-card {
  font-weight: 600; }
  .twitter-card img {
    height: 4rem;
    border: 5px solid #c61440;
    border-radius: 50%; }
  .twitter-card a:visited > img {
    border-color: #df3180; }
  .twitter-card a:hover > img {
    border-color: #f22670; }

.media {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-top: 2.5rem; }
  .media img,
  .media iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }

.media--16-by-9,
.media--video {
  padding-top: 56.65%; }

.media--4-by-3 {
  padding-top: 75%; }

.media--1-by-1,
.media--square {
  padding-top: 100%; }

.media--2-by-1,
.media--video-chat {
  max-width: 37em;
  margin: 1rem auto 0;
  padding-top: 50%; }

@media (min-width: 45rem) {
  .homepage main {
    padding: 0 1.5625rem; } }

@media (min-width: 60rem) {
  .homepage main {
    display: grid;
    position: relative;
    top: -3.05176rem;
    margin: 0 auto;
    grid-column-gap: 1.5625rem;
    grid-template-areas: '------- venue' 'tickets venue' 'tickets speakers' 'tickets speakers' 'lws     speakers' 'lws     speakers' 'lws     ------';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3.05176rem repeat(2, auto auto 3.05176rem);
    max-width: 55.5em; } }

.homepage main > section {
  padding: 0; }

.homepage main > section:nth-child(1) {
  grid-area: venue; }

@media (min-width: 36rem) {
  .homepage main > section:nth-child(1) > h2:first-of-type {
    margin-top: 0; } }

.homepage main > section:nth-child(2) {
  grid-area: tickets; }

.homepage main > section:nth-child(3) {
  grid-area: speakers; }

.homepage main > section:nth-child(4) {
  grid-area: lws; }

@media (min-width: 45rem) {
  .speakers .speaker-list {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 1fr;
    max-width: 55.5em; } }

@media (min-width: 45rem) {
  .speaker {
    display: grid;
    grid-gap: 1.5625rem;
    grid-template-areas: 'speaker talk' 'nav -----';
    grid-template-columns: 1fr 60%; } }

.speaker__talk {
  position: relative;
  grid-area: talk; }
  @media (min-width: 45rem) {
    .speaker__talk {
      top: -6.10352rem; } }

.speaker__info {
  grid-area: speaker; }
  .speaker__info .list-inline {
    clear: left; }

.speaker > footer {
  grid-area: nav; }

.speaker .image-wrap {
  margin: 1.5625rem 1.25rem 1.25rem 0;
  float: left; }

.sponsors main {
  margin: 0 auto;
  max-width: 55.5em; }
  @media (min-width: 60rem) {
    .sponsors main {
      display: grid;
      grid-template-areas: 'aside headline' 'aside major' 'aside medium' 'aside small';
      grid-template-columns: 1fr 60%; } }
  .sponsors main h1 {
    margin: 1.25rem;
    grid-area: headline; }
    @media (min-width: 36rem) {
      .sponsors main h1 {
        margin-bottom: 3.05176rem; } }
    @media (min-width: 60rem) {
      .sponsors main h1 {
        position: relative;
        top: -6.10352rem; } }
  .sponsors main > *:nth-child(2) {
    grid-area: headline; }
  .sponsors main > *:nth-child(3) {
    grid-area: major; }
  .sponsors main > *:nth-child(4) {
    grid-area: medium; }
  .sponsors main > *:nth-child(5) {
    grid-area: small; }
  .sponsors main > *:last-child {
    grid-area: aside; }
