.site-title {
  margin: 0;
  padding: 1rem;
  background-color: color('purple');
  color: contrast('background');
  font-size: size('text-l');
  line-height: size('line-height-narrow');

  @include above('small') {
    display: grid;
    padding: 0;
    background-color: transparent;
    grid-template-areas: 'title image';
    grid-template-columns: 1fr 60%;
  }

  &::before {
    content: '';
    display: block;
    height: 30vw;
    background-image: url('/images/SOTB-monitors.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include above('small') {
      // display: none;
      height: 15vw;
      margin: 1rem 1rem 0 0;
      grid-area: image;
    }
  }

  a {
    display: block;
    height: 3em;
    font-size: calc(3rem + 1.25 * (100vw - 20rem) / (60 - 20));
    font-weight: 800;
    grid-area: title;
    text-align: center;

    @include above('small') {
      height: 4em;
      margin: 0.5em 0 0 1rem;
      background-image: url('/images/halftone-pattern-circle.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  span {
    text-transform: uppercase;
  }

  span:first-child,
  span:nth-child(4) {
    display: block;
  }

  span:nth-child(2),
  span:nth-child(3) {
    height: 1em;
    position: relative;
    top: -1.2em;
    font-size: 0.5em;
  }

  span:first-child {
    margin-top: 0.5em;
    color: color('yellow-screen');

    @include above('small') {
      color: color('yellow');
    }
  }

  span:nth-child(2) {
    color: color('orange-screen');

    @include above('small') {
      color: color('orange');
    }
  }

  span:nth-child(3) {
    color: color('red-screen');

    @include above('small') {
      color: color('red');
    }
  }

  span:nth-child(4) {
    position: relative;
    top: -0.85em;
    color: color('purple-screen');

    @include above('small') {
      color: color('purple');
    }
  }
}
