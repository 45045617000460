@import 'node_modules/accoutrement-scale/sass/scale';

$sizes: (
  'root': 16px,
  'line-height': 1.4,
  'line-height-narrow': 1.2,
  'line-height-narrower': 1,
  'border': 3px,
  'rhythm': 'text-m',
  'padding-s': 'text-m',
  'padding': 'text-l',
  'padding-xl': 'text-xl',
  'text-xl': 'root' ('major-third': 5, 'convert-units': 'rem'),
  'text-l': 'root' ('major-third': 2, 'convert-units': 'rem'),
  'text-m': 'root' ('major-third': 1, 'convert-units': 'rem'),
);
