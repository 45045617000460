////
/// Details
/// @group base
////

details {
  margin-top: 1rem;

  pre {
    font-family: inherit;
    white-space: pre-wrap;
  }
}
