@import 'node_modules/accoutrement-type/sass/type';

// Fonts
$dosis: (
  'name': 'Dosis',
  'stack': ('Helvetica Neue', 'sans-serif'),
  '800': '/fonts/Dosis-ExtraBold.ttf',
  'formats': 'ttf'
);


@include add-font('text', $dosis);
@include font-face('text');
