.list-inline {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  grid-area: links;

  > li {
    margin-top: size('padding-s');
    margin-right: size('padding-s');
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
