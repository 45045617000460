////
/// Anchor
/// @group base
////

a {
  position: relative;
  color: color('link-active');
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: currentColor;
  text-decoration-skip: ink;
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus {
    color: color('link-hover');
  }

  &:visited {
    color: color('link-visited');
  }

  &.is-button, // stylelint-disable-line selector-no-qualifying-type
  &[rel~='external'] {
    display: inline-block;
    padding: 0.2rem 0.4rem;
    background-color: color('link-active');
    border-radius: 3px;
    color: contrast('link-active');
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: color('link-hover');
    }

    &:visited {
      background-color: color('link-visited');
    }
  }

  &.is-disabled { // stylelint-disable-line selector-no-qualifying-type
    cursor: not-allowed;
    text-decoration: line-through;

    &:hover {
      color: color('link-active');
    }
  }
}
