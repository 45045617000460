////
/// Media
/// @group components
////

// Contains embedded media properly and allows them to be dynamically resized
// inside a set aspect ratio container.
.media {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-top: 2.5rem;

  img,
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }
}

.media--16-by-9,
.media--video {
  padding-top: 56.65%
}

.media--4-by-3 {
  padding-top: 75%;
}

.media--1-by-1,
.media--square {
  padding-top: 100%
}

.media--2-by-1,
.media--video-chat {
  max-width: $max-paragraph-width;
  margin: 1rem auto 0;
  padding-top: 50%;
}
