////
/// Variables
/// @group abstracts
////

$max-paragraph-width: 37em;
$max-container-width: $max-paragraph-width * 1.5;


// ----  OLD STUFF -----
/// @type Length
$base-font-size: 18px !default;

/// @type Length
$wrapper-width-small: 500px !default;
/// @type Length
$wrapper-width-medium: 800px !default;
/// @type Length
$wrapper-width-large: 1000px !default;
/// @type Map
/// @prop {String} Breakpoint name
/// @prop {Length} Breakpoint value
$breakpoints: (
  small: $wrapper-width-small,
  medium: $wrapper-width-medium,
  large: $wrapper-width-large
) !default;

/// @type Length
$gutter-width: 1.25rem !default;
/// @type Length
$gap-width: 2.25rem !default;
/// @type Length
$aside-width: 15.875rem !default;
/// @type Length
$article-width: 50.625rem !default;
// 21em
/// @type Length
$line-length-minimum: 336px !default;
// 30em
/// @type Length
$line-length-maximum: 480px !default;
/// @type Length
$line-length-responsive: calc(#{$line-length-minimum} + (#{$line-length-maximum / ($line-length-maximum * 0 + 1)} - #{$line-length-minimum / ($line-length-minimum * 0 + 1)}) * ((100vw - #{$wrapper-width-small}) / (#{$wrapper-width-large / ($wrapper-width-large * 0 + 1)} - #{$wrapper-width-small / ($wrapper-width-small * 0 + 1)})));

/// @type Length
$measure-small: 0.625rem !default;
/// @type Length
$measure-small-relative: 0.625em !default;
/// @type Length
$measure-small-proportional: 2vmin !default;
/// @type Length
$measure-medium: 1.25rem !default;
/// @type Length
$measure-medium-relative: 1.25em !default;
/// @type Length
$measure-medium-proportional: 4vmin !default;
/// @type Length
$measure-large: 2.25rem !default;
/// @type Length
$measure-large-relative: 2.25em !default;
/// @type Length
$measure-large-proportional: 8vmin !default;
/// @type Length
$measure-one: 1rem !default;
/// @type Length
$measure-one-relative: 1em !default;
/// @type Map
/// @prop {String} Measure name
/// @prop {Length} Measure value
/// @see {Mixin} v
$measures: (
    small: $measure-small,
    small-relative: $measure-small-relative,
    small-proportional: $measure-small-proportional,
    medium: $measure-medium,
    medium-relative: $measure-medium-relative,
    medium-proportional: $measure-medium-proportional,
    large: $measure-large,
    large-relative: $measure-large-relative,
    large-proportional: $measure-large-proportional,
    one: $measure-one,
    one-relative: $measure-one-relative
) !default;

/// @type Number
$opacity-alpha: 0.9 !default;
/// @type Number
$opacity-beta: 0.5 !default;

/// @type Map
/// @prop {String} Opacity name
/// @prop {Number} Opacity value
/// @see {Function} opacities
/// @see {Mixin} v
$opacities: (
    alpha: $opacity-alpha,
    beta: $opacity-beta
) !default;

// this allows transparency to be transitioned to a different color
/// @type Color
$color-transparent: rgba(0, 0, 0, 0) !default;
/// @type Color
$color-black: #181818 !default;
/// @type Color
$color-white: white !default;
/// @type Color
$color-yellow: #dea702;
/// @type Color
$primary: (
    'yellow': #dea702,
    'orange': #c83f19,
    'red': #c61540,
    'purple': #702055,
);
$shades: (
    'yellow-light': #fae000,
    'yellow-secondary': #ecb156,
    'yellow-dark': #a87f1a,
    'orange-light': #f36e2d,
    'orange-secondary': #e05665,
    'orange-dark': #a13e17,
    'red-light': #f2266f,
    'red-secondary': #df3080,
    'red-dark': #9f1833,
    'purple-light': #af3a8e,
    'purple-dark': #51163d
);
// @include add-colors($primary, $shades);
$color-yellow-darkened: #a87f1a;
$color-orange: #c83f19;
$color-orange-darkened: #a13e17;
$color-red: #c61540;
$color-red-darkened: #9f1833;
$color-purple: #702055;
$color-purple-darkened: #51163d;
/// @type Map
/// @prop {String} Color Name
/// @prop {Color} Color Value
/// @see {Mixin} v
$site-colors: (
    transparent: $color-transparent,
    black: $color-black,
    white: $color-white,
    yellow: $color-yellow,
    orange: $color-orange,
    red: $color-red,
    purple: $color-purple
) !default;
/// @type Map
/// @prop {String} Color Name
/// @prop {Color} Color Value
$palette-colors: (
    black: $color-black,
    white: $color-white,
    yellow: $color-yellow,
    orange: $color-orange,
    red: $color-red,
    purple: $color-purple
) !default;

/// @type Length
$border-width-default: 0.25rem !default;
/// @type Length
$border-width-hairline: 1px !default;
/// @type Map
/// @prop {String} Border Width Name
/// @prop {Color} Border Width Value
/// @see {Mixin} v
$border-widths: (
    default: $border-width-default,
    hairline: $border-width-hairline,
) !default;

/// @type String
$border-style-default: solid !default;
/// @type String
$border-style-alternate: dotted !default;
/// @type Map
/// @prop {String} Border Style Name
/// @prop {Color} Border Style Value
/// @see {Mixin} v
$border-styles: (
    default: $border-style-default,
    alternate: $border-style-alternate
) !default;

/// @type Length
/// @see {Mixin} border-radius
$border-radius-default: 0.25rem !default;
/// @type Length
/// @see {Mixin} border-radius
$border-radius-hairline: 0.125rem !default;
/// @type Map
/// @prop {String} Border Radius Name
/// @prop {Color} Border Radius Value
/// @see {Mixin} v
$border-radii: (
    default: $border-radius-default,
    hairline: $border-radius-hairline
) !default;

/// @type String
$font-display: fallback !default;

/// @type Number
$font-weight-default: 400 !default;
/// @type Number
$font-weight-bold: 600 !default;
/// @type Map
/// @prop {String} Font Weight Name
/// @prop {Color} Font Weight Value
/// @see {Mixin} v
$font-weights: (
    default: $font-weight-default,
    bold: $font-weight-bold
) !default;

/// @type Length
$font-size-default: 1rem !default;
// 72px
/// @type Length
$font-size-alpha: 2.5rem !default;
// 22px
/// @type Length
$font-size-beta: 1.75rem !default;
// 18px
/// @type Length
$font-size-gamma: 1.25rem !default;
/// @type Map
/// @prop {String} Font Size Name
/// @prop {Number} Font Size Value
/// @see {Mixin} v
$font-sizes: (
    default: $font-size-default,
    alpha: $font-size-alpha,
    beta: $font-size-beta,
    gamma: $font-size-gamma
) !default;

/// @type List
$font-family-default: "Average Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
/// @type List
$font-family-serif: serif !default;
/// @type List
$font-family-title: "Fjalla One", sans-serif;
/// @type List
$font-family-monospace: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace !default;
/// @type Map
/// @prop {String} key - Font Family name
/// @prop {Number} value - Font Family value
/// @see {Mixin} font-family
/// @see {Mixin} v
$font-families: (
    default: $font-family-default,
    serif: $font-family-serif,
    title: $font-family-title,
    monospace: $font-family-monospace
) !default;
/// @type List
/// @prop {String} family - Font Family
/// @prop {String} variant - Font Family Variant
/// @prop {String} style - Font Style
/// @prop {Number} weight - Font Weight
/// @prop {String} filename - Font Filename
$font-faces: (("Fjalla One", "Regular", normal, 400, "fjalla-one"), ("Average", "Regular", normal, 400, "average"), ("Average Sans", "Regular", normal, 400, "average-sans")) !default;

/// @type Number
$line-height-default: 1.75 !default;
/// @type Number
$line-height-small: 1.25 !default;
/// @type Number
$line-height-medium: 1.5 !default;
/// @type Map
/// @prop {String} Line Height Name
/// @prop {Number} Line Height Value
/// @see {Mixin} v
$line-heights: (
    default: $line-height-default,
    small: $line-height-small,
    medium: $line-height-medium
) !default;

/// @type Length
$letter-spacing-default: 0 !default;
/// @type Length
$letter-spacing-large: 1px !default;

/// @type Length
$animation-duration: 2s !default;
/// @type String
$animation-function: linear !default;
/// @type String
$animation-fill-mode: forwards !default;

/// @type Length
$rotation-default: 5deg !default;

/// @type Length
/// @see {Mixin} transition
$transition-duration-default: 0.2s !default;
/// @type Length
/// @see {Mixin} transition
$transition-duration-long: 1s !default;
/// @type String
/// @see {Mixin} transition
$transition-function: ease !default;

/// @type Number
$z-index-below: -1 !default;
/// @type Number
$z-index-root: 0 !default;
/// @type Number
$z-index-default: 1 !default;
/// @type Number
$z-index-above: 2 !default;
/// @type Number
$z-index-modal: 3 !default;
/// @type Number
$z-index-dialog: 4 !default;
/// @type Number
$z-index-notification: 5 !default;
/// @type Map
/// @prop {String} z-index Name
/// @prop {Number} z-index Value
/// @see {Function} z-index
/// @see {Mixin} v
$z-indexes: (
    below: $z-index-below,
    root: $z-index-root,
    default: $z-index-default,
    above: $z-index-above,
    modal: $z-index-modal,
    dialog: $z-index-dialog,
    notification: $z-index-notification
) !default;

/// @type Map
/// @prop {Array} Aspect Ratio
/// @prop {Length} Percentage for sizing
$aspect-ratios: (
    "16-by-9": 56.25%,
    "4-by-3": 75%
) !default;

/// @type List
/// @prop {String} Body / Primary Navigation Class
$navigation-classes: speakers, schedule, location, sponsors !default;

/// @type Length
$icon-size: 40px !default;

/// @type Number
$max-columns: 2 !default;

/// @type Map
/// @see {Mixin} v
$variable-map: (
    border-width: $border-widths,
    border-style: $border-styles,
    color: $site-colors,
    font-family: $font-families,
    font-size: $font-sizes,
    font-weight: $font-weights,
    line-height: $line-heights,
    measure: $measures,
    opacity: $opacities,
    z-index: $z-indexes
);

/// @type Map
/// @see {Mixin} v
$property-map: (
    opacity: opacity,
    background-color: color,
    color: color,
    border-color: color,
    border-top-color: color,
    border-right-color: color,
    border-bottom-color: color,
    border-left-color: color,
    border-style: border-style,
    border-top-style: border-style,
    border-right-style: border-style,
    border-bottom-style: border-style,
    border-left-style: border-style,
    border-width: border-width,
    border-top-width: border-width,
    border-right-width: border-width,
    border-bottom-width: border-width,
    border-left-width: border-width,
    fill: color,
    font-family: font-family,
    font-size: font-size,
    font-weight: font-weight,
    line-height: line-height,
    margin: measure,
    margin-top: measure,
    margin-right: measure,
    margin-bottom: measure,
    margin-left: measure,
    padding: measure,
    padding-top: measure,
    padding-right: measure,
    padding-bottom: measure,
    padding-left: measure,
    stroke: color,
    text-decoration-color: color,
    text-decoration-width: border-width,
    z-index: z-index
);
