.speaker {

  @include above('medium') {
    display: grid;
    grid-gap: size('padding');
    grid-template-areas: 'speaker talk'
      'nav -----';
    grid-template-columns: 1fr 60%;
  }

  &__talk {
    position: relative;
    grid-area: talk;

    @include above('medium') {
      top: -#{size('padding-xl')*2};
    }
  }

  &__info {
    grid-area: speaker;

    .list-inline {
      clear: left;
    }
  }

  > footer {
    grid-area: nav;
  }

  .image-wrap {
    margin: size('padding') size('padding-s') size('padding-s') 0;
    float: left;
  }
}
