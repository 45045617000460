.sponsor-list {
  display: flex;
  // flex-wrap: wrap;
  justify-content: center;

  .logo {
    // padding: size('padding-s');
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > * + * {
    margin-left: size('padding-s');
  }

  &--headline {
    .logo {
      flex-basis: 400px;
    }
  }

  &--major {
    .logo {
      flex-basis: 400px;
    }

    img {
      max-width: 50%;
    }

    p:first-of-type {
      text-align: center;
    }
  }

  &--medium {
    .logo {
      flex-basis: 250px;
    }
  }

  &--small {
    .logo {
      flex-basis: 200px;
      justify-self: center;
    }
  }

  img {
    width: 100%;
  }

}
