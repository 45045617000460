.footer-content {
  margin: 0 auto;
  max-width: $max-container-width;

  @include above('small') {
    display: grid;
    grid-gap: size('rhythm');
    grid-template-areas: 'info links';
    grid-template-columns: 60% 1fr;
  }

  &__information {
    grid-area: info;
  }

  img {
    float: none;
    max-width: 150px;
  }
}
