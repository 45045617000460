.image-wrap {
  position: relative;
  border-color: color('purple');
  border-style: solid;
  border-width: 7px 30px 7px 10px;
  line-height: 0;

  img {
    max-width: 100%;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    background-blend-mode: screen;
    background-color: color('red');
    background-size: cover;

    &:hover {
      background-color: color('orange');
    }
  }

  a > img {
    visibility: hidden;
  }

  &--portrait,
  &--no-anchor {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
  }

  &--portrait {
    width: 200px;
    border-width: 10px 7px 30px;

    &::after {
      background-color: color('red');
      mix-blend-mode: screen;
    }
  }

  &--no-anchor {
    &::after {
      background-color: color('dark-grey');
      mix-blend-mode: lighten;
    }
  }
}
