/// nav-menu
/// @see https://medium.com/@heyoka/responsive-pure-css-off-canvas-hamburger-menu-aebc8d11d793
/// and https://codepen.io/markcaron/pen/pPZVWO?editors=1100
.nav-menu {
  display: none;

  @include above('small') {
    display: block;
  }

  &:target,
  &[aria-expanded='true'] {
    display: block;
  }

  &:target &__entries,
  &[aria-expanded='true'] &__entries {
    display: block;
  }

  &:target ~ &__toggle {
    display: none;
  }

  li {
    @include above('small') {
      display: inline-block;
      padding: 0.4em 0.8em;
    }
  }

  &__entries {
    margin: 0.5em 0 0;
    padding-left: 0;
    list-style: none;
    text-align: center;

    @include above(small) {
      margin-top: 0;
    }

    > * + * {
      margin-top: 0.3em;
    }
  }

  &__toggle,
  &__close {
    padding-left: 27px;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: 20px;
    text-decoration: none;
    text-transform: uppercase;

    &:visited {
      color: color('red');
    }
  }

  &__toggle {
    background-image: url('/images/icon-menu.svg');

    @include above('small') {
      display: none;
    }
  }

  &__close {
    height: 1em;
    background-image: url('/images/icon-menu-close.svg');

    @include above('small') {
      display: none;
    }
  }
}
