section {
  margin-bottom: size('padding-xl');

  @include above('medium') {
    padding: 0 size('padding');
  }

  &:first-child > h2:first-child {
    margin-top: size('padding');
  }

  > h1,
  > h2 {
    padding: 0 size('padding');
    padding-bottom: 20px; // fixed size due to the way the gradient is calculated
    background-color: color('purple');
    background-image: rainbow-gradient();
    color: contrast('purple');

    @include above('small') {
      padding-bottom: 0;
      background-color: transparent;
      background-image: none;
      color: color('red');
    }

    &.no-bg { // stylelint-disable selector-no-qualifying-type
      padding-bottom: 0;
      background-color: transparent;
      background-image: none;
      color: color('red');
    }
  }

  > ul,
  > dl,
  > p {
    padding: 0 size('padding');

    @include above('medium') {
      padding: 0;
    }
  }
}
