////
/// Page
/// @group base
////
@include global-box-sizing;

html {
  @include font-family(text);
  position: relative;
  font-size: 100%; /* this means 16px */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: oldstyle-nums;
  font-weight: 300;
  line-height: size('line-height');
  min-height: 100%;
  overflow-x: hidden;
  // prevent horizontal jump when navigating short page <--> tall page
  overflow-y: scroll;
  // prevent adjustments of font-size after orientation changes in IE on
  // Windows Phone and in iOS
  // stylelint-disable property-no-vendor-prefix
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  // stylelint-enable

  @include above('small') {
    font-size: 112.5%; /* this means 18px */
  }

  @include above('medium') {
    font-size: 125%; /* this means 20px */
  }
}

body {
  margin: 0;
  background-color: color('background');
  color: color('text');

  @include above('small') {
    background-image: url('/images/bg-tile.svg');
    background-repeat: repeat;
    background-size: 50px;
  }
}
