////
/// Helpers
/// @group components
////

// Hide elements visually
[hidden],
.is-hidden {
  @include is-hidden;
}

// hidden element but focusable
.is-focusable {
  &:not([href]),
  &[href]:not(:focus):not(:active) {
    @include is-hidden;
  }
}

// // Hyphenate any looooooooooooooooooooo-
// // oooooong text
// .hyphen,
// .hyphenate {
//   @include hyphenate;
// }

// // Make things non-selectable
// .non-selectable {
//   @include non-selectable;
// }

// // Make things non-interactive
// .non-interactive {
//   cursor: not-allowed; // kind of redundant
//   pointer-events: none;
// }
