////
/// Quote
/// @group base
////

blockquote {
  color: color('orange');
  font-weight: 600;
  max-width: $max-paragraph-width;

  cite,
  em {
    font-style: normal;
  }

  p {
    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  }

  cite {
    display: block;
    font-style: normal;
    text-align: right;

    &::before {
      // EN DASH, NO-BREAK SPACE
      content: '– ';
    }
  }
}
