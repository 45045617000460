////
/// Paragraph
/// @group base
////

p {
  max-width: $max-paragraph-width;

  > img {
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    float: left;
  }
}
