////
/// Miscellaneous
/// @group base
////

hr {
  display: block;
  width: 100%;
  border: 0;
  // show the overflow in Edge and IE
  overflow: visible;
}

abbr[title],
dfn[title] {
  border-bottom-color: color('text');
  border-bottom-style: alternate;
  border-bottom-width: hairline;
  color: color('text');
  cursor: help;
  font-style: italic;
}

abbr[title],
dfn[title],
ins {
  text-decoration: none;
}

strong {
  font-weight: 600;
}

sub,
sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.4em;
  font-variant-position: sub;
}

sup {
  top: -0.4em;
  font-variant-position: super;
}

// Touch Action
a,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

// Mouse Selection
::selection {
  background-color: color('selection-bg');
  color: color('selection');
}
